import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Grid, InputLabel, Select, styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import ReiForms from 'js/constants/reiForms';
import ReiAPI from 'js/api/reiForms';
import CLAPI from 'js/api/clapi';
import { cruxLoader, cruxUnloader } from 'js/actions/crux';
import { getFormTemplates, getUserTemplates } from 'js/actions/reiForms';
import { trackEvent } from 'js/actions/segment';
import { createMixpanelProperties } from 'js/helpers/Segment';
import Segment from 'js/constants/segment';
import { routeCodes } from 'js/constants/routes';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    maxWidth: '600px',
    whiteSpace: 'normal',
}))

const hoverStyle = {
    '&:hover:not(.Mui-disabled)': {
        '& fieldset': {
            borderColor: '#3399ff',
            borderWidth: '2px',
        },
    },
};

const FormsTemplate = (props) => {
    const { handleClose, addressState } = props;
    const PLACEHOLDER_VALUE = 0;
    const [formTemplate, setFormTemplate] = useState(PLACEHOLDER_VALUE);
    const [mainTemplate, setMainTemplate] = useState(PLACEHOLDER_VALUE);
    const [subTemplate, setSubTemplate] = useState(PLACEHOLDER_VALUE);
    const [formName, setFormName] = useState(null);
    const [error, setError] = useState(null);
    const templateTypes = ReiForms.FORM_TEMPLATES;
    const type = ReiForms.FORM_TYPES_STATE[addressState];
    const integrationType = ReiForms.FORM_LABEL[type];
    const propertyId = useSelector(state => state.rapid.get('targetProperty')?.value?.id);
    const formTemplates = useSelector(state => state.reiForms.get('formTemplates'))?.[addressState];
    const userTemplates = useSelector(state => state.reiForms.get('userTemplates'))?.[addressState];
    const dispatch = useDispatch();
    const history = useHistory();
    const isUserTemplate = Boolean(formTemplate === 2);
    const mainTemplateLabel = isUserTemplate ? 'Template' : 'Form Type'

    useEffect(() => {
        if (formTemplates?.statusCode === 400 || userTemplates?.statusCode === 400) {
            setError(1); // error from getting templates
        } else {
            setError(null);
        }
    }, [JSON.stringify(formTemplates), JSON.stringify(userTemplates)]);

    useEffect(() => {
        // fetch if success is null or false
        if (formTemplates?.statusCode !== 200) {
            dispatch(getFormTemplates(addressState))
        }
        if (userTemplates?.statusCode !== 200) {
            dispatch(getUserTemplates(addressState))
        }
    }, []);

    const getTemplatesResponse = (templateId) => {
        if (templateId === 1) {
            return formTemplates?.response;
        } else if (templateId === 2) {
            return userTemplates?.response;
        }
        return [];
    };

    const removeHtmlAnchorsAndParentheses = (input) => {
        return input.replace(/\s*\(\s*<a[^>]*>.*?<\/a>\s*\)/gi, '');
    }

    const getMainFormTypeMenu = () => {
        let menuItems = getTemplatesResponse(formTemplate);
        if (!menuItems?.length) {
            return null;
        }
        return menuItems?.map(template =>
            <StyledMenuItem
                key={template?.id}
                value={template?.id}
            >
                {template?.name}
            </StyledMenuItem>);
    };

    const getSubFormTypeMenu = () => {
        if (mainTemplate) {
            return formTemplates?.response
                ?.find(template => template?.id === mainTemplate)?.templates
                ?.map(template => (
                    <StyledMenuItem
                        key={template?.id}
                        value={template?.id}
                    >
                        {removeHtmlAnchorsAndParentheses(template?.name)}
                    </StyledMenuItem>
                ));
        }
        return null;
    };

    const handleChangeTemplate = (event) => {
        setFormTemplate(event.target.value);
        setMainTemplate(PLACEHOLDER_VALUE);
        setSubTemplate(PLACEHOLDER_VALUE);
    };

    const handleChangeMainTemplate = (event) => {
        setMainTemplate(event.target.value);
        setSubTemplate(PLACEHOLDER_VALUE);
    };

    const getFormLabel = () => {
        return isUserTemplate ?
            null :
            removeHtmlAnchorsAndParentheses(formTemplates?.response
                ?.find(t => t?.id === mainTemplate)?.templates
                ?.find(t => t?.id === subTemplate)?.name || '');
    };

    const getFormTypeLabel = () => {
        return isUserTemplate ?
            null :
            removeHtmlAnchorsAndParentheses(formTemplates?.response
                ?.find(t => t?.id === mainTemplate)?.name || '');
    };

    const getTemplateLabel = () => {
        return isUserTemplate ?
            userTemplates?.response
                ?.find(t => t?.id === mainTemplate)?.name :
            null;
    };

    const getTooltipForMainTemplate = () => {
        if (!mainTemplate) {
            return null;
        }

        return getTemplatesResponse(formTemplate)
            ?.find(t => t?.id === mainTemplate)
            ?.name;
    };

    const getTooltipForSubTemplate = () => {
        if (!subTemplate) {
            return null;
        }
        return formTemplates?.response
            ?.find(t => t?.id === mainTemplate)?.templates
            ?.find(t => t?.id === subTemplate)?.name;
    };

    const handleCreateForm = () => {
        dispatch(cruxLoader());
        ReiAPI.submitForm({
            state: addressState,
            clAppAcctUserGuid: CLAPI.getSelectedClAppAccountUserGuid(),
            formName,
            propertyId,
            templateId: isUserTemplate ? mainTemplate : subTemplate,
            isUserTemplate,
        }).then((response) => {
            if (response?.statusCode === 200) {
                window.open(response?.displayUrl, '_blank');
                handleClose();

                dispatch(trackEvent(createMixpanelProperties(Segment.EVENT_NAMES.CREATE_FORM, {
                    page: 'Property Detail',
                    integration: integrationType,
                    propertyId,
                    formType: getFormTypeLabel(),
                    form: getFormLabel(),
                    template: getTemplateLabel(),
                })));
                setError(null);
            } else {
                setError(2); // token is invalid or expired
            }
        }).catch(() => {
            setError(3); // API is unavailable or unresponsive
        }).finally(() => {
            dispatch(cruxUnloader());
        });
    };

    const placeholder = (
        <StyledMenuItem value={PLACEHOLDER_VALUE}>
            <em style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Please select</em>
        </StyledMenuItem>
    );

    const redirectToIntegrations = () => {
        history.push({
            pathname: routeCodes.ACCOUNT.path,
            state: {
                activeTab: 'integration-tab',
            },
        });
    };

    return (
        <>
            <DialogContent sx={{ width: 600, overflow: 'visible' }}>
                <Grid container spacing={2}>
                    {
                        error === 1 &&
                        <Grid item xs={12}>
                            <Alert severity="error">
                                We cannot connect to {integrationType} at this time. Please try again later.
                            </Alert>
                        </Grid>
                    }
                    {
                        error === 2 &&
                        <Grid item xs={12}>
                            <Alert severity="error">
                                Your token is invalid or expired. Please check your token in {integrationType} and update
                                via the <Link onClick={redirectToIntegrations}>Integrations</Link> page in My Account.
                            </Alert>
                        </Grid>
                    }
                    {
                        error === 3 &&
                        <Grid item xs={12}>
                            <Alert severity="error">
                                Sorry, there was an error connecting to {integrationType}.
                                Please refresh the page to try again.
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <FormControl fullWidth size="small" disabled={error === 1}>
                            <InputLabel id="create-new-label">Create New</InputLabel>
                            <Select
                                data-testid="create-new-form-select"
                                aria-labelledby="create-new-label"
                                label="Create New"
                                value={formTemplate}
                                onChange={handleChangeTemplate}
                                sx={hoverStyle}
                            >
                                {placeholder}
                                {
                                    templateTypes.map(({ value, label }) =>
                                        <StyledMenuItem
                                            key={value}
                                            value={value}
                                            disabled={!getTemplatesResponse(value)?.length}
                                        >
                                            {label}
                                        </StyledMenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <FormControl fullWidth size="small" disabled={!formTemplate}>
                            <InputLabel id="main-template-select">{mainTemplateLabel}</InputLabel>
                            <Select
                                data-testid="main-template-select"
                                aria-labelledby="main-template-select"
                                label={mainTemplateLabel}
                                value={mainTemplate}
                                onChange={handleChangeMainTemplate}
                                title={getTooltipForMainTemplate()}
                                sx={hoverStyle}
                            >
                                {placeholder}
                                {getMainFormTypeMenu()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            !isUserTemplate &&
                            <FormControl fullWidth size="small" disabled={!mainTemplate}>
                                <InputLabel id="sub-template-select">Form</InputLabel>
                                <Select
                                    title={getTooltipForSubTemplate()}
                                    data-testid="sub-template-select"
                                    aria-labelledby="sub-template-select"
                                    label="Form"
                                    value={subTemplate}
                                    onChange={(event) => setSubTemplate(event.target.value)}
                                    sx={hoverStyle}
                                >
                                    {placeholder}
                                    {getSubFormTypeMenu()}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            data-testid="form-name-text"
                            disabled={error === 1}
                            label="Form Name"
                            size="small"
                            fullWidth
                            onChange={event => setFormName(event.target.value?.trim())}
                            sx={hoverStyle}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    paddingRight: '24px',
                    paddingBottom: '24px',
                    paddingTop: 0,
                }}
            >
                <Button
                    color="primary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateForm}
                    disabled={!formTemplate || !mainTemplate || (formTemplate === 1 && !subTemplate) || !formName}
                >
                    Create Form
                </Button>
            </DialogActions>
        </>
    );
};

FormsTemplate.propTypes = {
    handleClose: PropTypes.func,
    addressState: PropTypes.string,
};

export default FormsTemplate;
