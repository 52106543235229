import { Map as IMap } from 'immutable';
import {
    SET_MULTI_SELECT_REPORT_SECTION_SUCCESS,
    SSR_CHECKBOX_STATUS,
    SSR_PROPERTY_TYPE_SUCCESS,
} from '../actions/customiseReport';
import listPropertyPreview from '../constants/listPropertyPreview';
import { isAU, isNZ } from '../constants/crux';

const DEFAULT_FIELDS = {
    propertyImage: true,
    attributes: true,
    propertyType: true,
    devZoning: true,
    landUse: true,
    coverImage: true,
    ...(isAU && { eqBuildingArea: true }),
    yearBuilt: true,
    ...(isAU ? { lotPlan: true } : { legalDescription: true }),
};

if (isNZ) {
    DEFAULT_FIELDS.capitalValue = true;
    DEFAULT_FIELDS.valuationDate = true;
}

const DEFAULT_SALE_FIELDS = {
    salePrice: true,
    saleDate: true,
    saleType: true,
};

const DEFAULT_FOR_SALE_RELATED_FIELDS = {
    firstPublished: true,
    daysOnMarket: true,
};

const DEFAULT_LISTING_FIELDS = {
    listingPrice: true,
    listingDate: true,
    listingType: true,
};

const addDistanceToSubSections = (defaultValue) => {
    Object.values(defaultValue).forEach(value => {
        Object.defineProperty(value.subSection,'distance', {
            value: true,
            enumerable: true,
            writable: true,
        });
    });
    return defaultValue;
};

export const DEFAULT_MULTI_SELECT_REPORT_SECTION = {
    [listPropertyPreview.EVENT_TAB.ALL]: {
        headerAndFooter: true,
        displayCoverPage: true,
        propertyList: true,
        subSection: {
            ...DEFAULT_FIELDS,
            ownerName: true,
            ...DEFAULT_SALE_FIELDS,
            ownerType: true,
        }
    },
    [listPropertyPreview.EVENT_TAB.RECENT_SALE]: {
        headerAndFooter: true,
        displayCoverPage: true,
        soldProperties: true,
        subSection: {
            ...DEFAULT_FIELDS,
            ...DEFAULT_FOR_SALE_RELATED_FIELDS,
            ...DEFAULT_SALE_FIELDS,
            salesLastSaleAgent: true,
            salesLastSaleAgency: true,
        },
        summary: true,
    },
    [listPropertyPreview.EVENT_TAB.FOR_SALE]: {
        headerAndFooter: true,
        displayCoverPage: true,
        onTheMarketProperties: true,
        subSection: {
            ...DEFAULT_FIELDS,
            ...DEFAULT_FOR_SALE_RELATED_FIELDS,
            ...DEFAULT_LISTING_FIELDS,
            agent: true,
            agency: true,
        },
        summary: true,
    },
    [listPropertyPreview.EVENT_TAB.AGED_LISTING]: {
        headerAndFooter: true,
        displayCoverPage: true,
        agedListingProperties: true,
        subSection: {
            ...DEFAULT_FIELDS,
            ...DEFAULT_FOR_SALE_RELATED_FIELDS,
            ...DEFAULT_LISTING_FIELDS,
            agent: true,
            agency: true,
        },
    },
    [listPropertyPreview.EVENT_TAB.WITHDRAWN_LISTING]: {
        headerAndFooter: true,
        displayCoverPage: true,
        withdrawnListingProperties: true,
        subSection: {
            ...DEFAULT_FIELDS,
            ...DEFAULT_FOR_SALE_RELATED_FIELDS,
            ...DEFAULT_LISTING_FIELDS,
            agent: true,
            agency: true,
        },
    },
    [listPropertyPreview.EVENT_TAB.TENURE]: {
        headerAndFooter: true,
        displayCoverPage: true,
        tenureProperties: true,
        subSection: {
            ...DEFAULT_FIELDS,
            ...DEFAULT_FOR_SALE_RELATED_FIELDS,
            ...DEFAULT_SALE_FIELDS,
            salesLastSaleAgent: true,
            salesLastSaleAgency: true,
        },
        summary: true,
    },
    [listPropertyPreview.EVENT_TAB.FOR_RENT]: {
        headerAndFooter: true,
        displayCoverPage: true,
        rentalComparisonProperties: true,
        subSection: {
            ...DEFAULT_FIELDS,
            ...DEFAULT_FOR_SALE_RELATED_FIELDS,
            rentalPrice: true,
            rentalDate: true,
            agent: true,
            agency: true,
        },
        summary: true,
    },
};

export const initialState = IMap({
    multiSelect: {
        address: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        radius: addDistanceToSubSections(JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION))),
        name: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        company: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        parcel: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        'legal description': JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        title: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        rollValuation: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        building: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        volumeFolio: JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        'savedList': JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
        'savedTerritory': JSON.parse(JSON.stringify(DEFAULT_MULTI_SELECT_REPORT_SECTION)),
    },
    ssr: {
        checkboxStatus: {
            areaProfile: '',
            marketTrends: '',
            medianValue: '',
            salesPerAnnum: '',
            salesPriceCvRatio: '',
            salesByPrice: '',
            changeInMedianValue: '',
            forSaleStatistics: '',
            rentalStatistics: '',
            householdStructure: '',
            populationAge: '',
            householdIncome: '',
            education: '',
            occupation: '',
        },
        propertyTypes: isAU ? {
            all: true,
            house: false,
            unit: false,
            land: false,
        } : {
            all: true,
            residential: false,
            land: false,
        },
    }
});

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_MULTI_SELECT_REPORT_SECTION_SUCCESS: {
            return state.merge(IMap({
                multiSelect: action.payload,
            }));
        }
        case SSR_CHECKBOX_STATUS: {
            const payload = action.payload || {};
            let newCheckboxStatus = {};
            Object.keys(payload)?.forEach((key) => {
                const checkboxStatus = state.get('ssr')?.checkboxStatus;
                if (checkboxStatus[key] === '' || payload[key] === '') {
                    newCheckboxStatus[key] = payload[key];
                }
            });
            return state.mergeDeep(IMap({
                ssr: {
                    checkboxStatus: {
                        ...(state.get('ssr')?.checkboxStatus || {}),
                        ...newCheckboxStatus,
                    },
                },
            }));
        }
        case SSR_PROPERTY_TYPE_SUCCESS: {
            return state.mergeDeep(IMap({
                ssr: {
                    propertyTypes: action.payload,
                }
            }));
        }
        default:
            return state;
    }
};
